<template>
	<div class="rtc-container">
		<div class="info-container" :class="$isMobile && 'info-container-mobile'">
			<!-- Log 展示区域 -->
			<div v-if="isHostMode" class="log-container" ref="logContainer" style="display: none;">
				<p class="log-label">Log:</p>
				<div v-for="(item, index) in logList" :key="index">
					<span class="log-state" v-if="item.type === 'success'">🟩 </span>
					<span class="log-state" v-if="item.type === 'failed'">🟥 </span>
					<span>{{ item.log }}</span>
				</div>
			</div>
			<!-- 直播结束 -->
			<!-- 			<div v-if="false"
				style="position: fixed;top: 0;left: 0;right: 0;bottom: 0;background-color:#060427;z-index: 10000;color: #fff;display: flex;flex-direction: column;align-items: center;">
				<div style="margin-top: 180px;">
					{{}}
				</div>
				<div style="margin-top: 90px;margin-bottom: 32px;">
					<img src="./a.png" style="width: 112px;height: 112px;" alt="">
				</div>
				<div>
					直播名称
				</div>
			</div> -->
			<!-- 远端流区域 -->
			<div class="remote-container" v-show="remoteStreamList" style="overflow: hidden;height: 240px;">
				<div v-if="item.type_ === 'auxiliary'" v-for="item,index in remoteStreamList" :key="item.id"
					:id="item.getUserId()" class="remote-stream-container"></div>
				<div v-if="item.type_ === 'main'" v-for="item,index in remoteStreamList" :key="item.id"
					:id="item.getUserId()" class="remote-stream-container"></div>
				<!-- <div v-if="item.type_ == 'main' " v-for="item in remoteStreamList"
					:key="item.getUserId()" :id="item.getUserId()" class="remote-stream-container"></div> -->
				<!-- <div v-for="item in remoteStreamList"
					:key="item.getUserId()" :id="item.getUserId()" class="remote-stream-container"></div> -->
				<!-- <div v-if="item.userId_.includes('share_user_')" v-for="item in remoteStreamList"
					:key="item.getUserId()" :id="item.getUserId()" class="remote-stream-container"></div>
				<div v-if="item.userId_.includes('user_')" v-for="item,index in remoteStreamList" :key="item.id"
					:id="item.getUserId()" class="remote-stream-container"></div> -->
				<div v-if="remoteStreamList.length == 0"
					style="width: 100%;height: 100%;background-color: rgba(0, 0, 0, 0.4);display: flex;justify-content: center;align-items: center;color: #fff;">
					{{live_state==2?'直播结束':'正在加载...'}}
				</div>
			</div>
			<!-- 本地流区域 -->
			<div class="Smallwindow">
				<div v-show="isAnchor && localStream" class="local-stream-container ">
					<!-- 本地流播放区域 -->
					<div id="localStream" class="local-stream-content"></div>
					<!-- 本地流操作栏 -->
					<div v-if="isPlayingLocalStream" class="local-stream-control">
						<div class="video-control control">
							<span v-if="!isMutedVideo" @click="muteVideo">
								<svg-icon icon-name="video" class="icon-class"></svg-icon>
							</span>
							<span v-if="isMutedVideo" @click="unmuteVideo">
								<svg-icon icon-name="video-muted" class="icon-class"></svg-icon>
							</span>
						</div>
						<div class="audio-control control">
							<span v-if="!isMutedAudio" @click="muteAudio">
								<svg-icon icon-name="audio" class="icon-class"></svg-icon>
							</span>
							<span v-if="isMutedAudio" @click="unmuteAudio">
								<svg-icon icon-name="audio-muted" class="icon-class"></svg-icon>
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- <div class="flex aic"
			style="height: 24px;background:rgba(250, 250, 250, 0.2);border-radius: 0px 0px 0px 0px;padding: 0 16px;">
			<span style="font-size: 12px;color: #fff;">当前观看直播人数32人</span>
		</div> -->
		<div style="height:60%;overflow: auto;padding-bottom: 80px;" ref="record">
			<div class="flex jcsb" style="margin: 0 8px;margin-bottom: 10px;" v-for="(item,index) in  messageerList"
				:key="index">
				<div
					style="max-width: 222px; background: rgba(0, 0, 0, 0.4);border-radius: 32rpx;font-size: 14px;padding: 10px 20px;border-radius: 32px;">
					<span style="color:#FF9E0B;">{{item.nick}}：</span>
					<span style="color: #fff;">{{item.renderContent}}</span>
				</div>
				<!-- <div class="flex aic">
				<img src="https://img0.baidu.com/it/u=1108567612,316425748&fm=253&fmt=auto?w=889&h=500" alt="" style="width: 15px; height: 15px;">
			</div> -->
			</div>
		</div>
		<div class=""
			style="position: fixed;bottom: 0;left: 0;right: 0;background-color: #000;height:80px; display: flex;align-items: center;">
			<div class="flex" style="width: 100%; font-size: 14px;color: #fff;margin: 0 14px;">
				<div style="width: 100%; display: flex;justify-content: space-between;">
					<div style="flex: 2;">
						<el-input placeholder="说点什么..." suffix-icon=" " v-model="textarea1">
						</el-input>
					</div>
					<div @click.stop="sendOut"
						style="flex: 1; color: #fff;white-space: nowrap;display: flex;align-items: center;justify-content: center;">
						<div style="background-color: #1FCEA1;display: block;padding: 10px 30px;border-radius: 15px;">发送
						</div>
					</div>
				</div>
				<!-- <div class="flex aic fdc" style="margin-left: 10px;" @click.stop="Continuouswheat">
					<img v-if="!isAnchor" src="./a.png" alt="" style="width: 20px;height: 20px;">
					<img v-if="isAnchor" src="./aa.png" alt="" style="width: 20px;height: 20px;">
					<span style="white-space: nowrap;">连麦</span>
				</div> -->
			</div>
		</div>
	</div>
</template>
<script type="text/javascript" src="https://res.wx.qq.com/open/js/jweixin-1.3.2.js"></script>

<script>
	import rtc from "./mixins/rtc.js";
	import {
		decodeText
	} from "@/utils/decodeText"
	import TIM from 'tim-js-sdk';
	import shareRtc from "./mixins/share-rtc.js";
	import LibGenerateTestUserSig from "@/utils/lib-generate-test-usersig.min.js";

	import {
		exam_list,
		user_sign,
		applet_info,
		live_detail
	} from "../api/mapRuquest.js";
	let options = {
		SDKAppID: 1400728399
	}
	let tim = TIM.create(options);
	tim.setLogLevel(1);

	export default {
		name: "compRoom",
		mixins: [rtc, shareRtc],
		props: {
			type: String,
			sdkAppId: Number,
			secretKey: String,
			userId: String,
			roomId: Number,
			cameraId: String,
			microphoneId: String,
			inviteUserSig: String,
		},
		data() {
			return {
				logList: [],
				inviteLink: "",
				showCopiedTip: false,
				isfollow: false, //是否关注
				model: false, //测批试卷
				value: '', //说点什么
				liveExamList: '', //直播间测试题
				tim: "",
				textarea1: '',
				isAnchor: false, //是否
				messageerList: [],
				Appuser: {}, //用户信息
				live_state: '',
			};
		},
		created() {
			console.log(this.$route.query, "获取上一页参数");
			this.sdkAppId = 1400728399;
			this.secretKey = "7c4a925eb1a7cf23f208a58deefaac2aee2996e39e03f38b12c6f39803854a14"
			
			// this.sdkAppId = 1400780809;
			// this.secretKey = "2a63ae6ef8e26acb3ba1ef4f194bdeb5b0014900a9605fa4fad01b75925df029"
			
			
			if (this.$route.query && this.$route.query.userId && this.$route.query.roomId && this.$route.query.liveid) {
				this.userId = this.$route.query.userId;
				this.roomId = Number(this.$route.query.roomId);
				this.liveid = this.$route.query.liveid;
				localStorage.setItem('token', this.$route.query.token)
				localStorage.setItem('userId', this.$route.query.userId)
				localStorage.setItem('roomId', this.$route.query.roomId)
				localStorage.setItem('liveid', this.$route.query.liveid)
			} else {
				this.userId = `${localStorage.getItem("userId")}`
				this.roomId = Number(localStorage.getItem("roomId"))
				this.liveid = localStorage.getItem("liveid")
			}
			// this.userId = '173'
			// this.roomId = Number(6426)
			// console.log('this.userId', this.userId, 'this.roomId', this.roomId, 'this.liveid', this.liveid)

			this.$nextTick(() => {
				this.handleJoinRoom();
			});
			this.getAppuser() //获取用户信息
			this.handleTimEvents();
			live_detail({
				id: this.liveid
			}).then((res) => {
				console.log(res, '直播详情')
				this.live_state = res.data.data.live.state;
				this.groupId = res.data.data.live.groupId;
				this.generateInviteLink();
			})
		},
		computed: {
			isHostMode() {
				return this.type !== "invite";
			},
			isEnLang() {
				return this.$i18n.locale === "en";
			},
			showInviteLink() {
				return (
					this.isHostMode &&
					(this.isJoined || this.isShareJoined) &&
					this.inviteLink
				);
			},
		},
		watch: {
			cameraId(val) {
				this.switchDevice("video", val);
			},
			microphoneId(val) {
				this.switchDevice("audio", val);
			},
		},
		destroyed() {
			console.log("销毁");
			this.handleLeave()
		},
		methods: {
			async getAppuser() {
				let res = await applet_info()
				console.log(res, '获取用户信息')
				this.Appuser = res.data.data
			},
			// async getexamList() {
			// 	let id = {
			// 		exam: '4b72423b-47e2-4647-b105-401eb340b996', //题库id
			// 		live: localStorage.getItem("liveid")
			// 	}
			// 	const res = await exam_list({
			// 		...id
			// 	});
			// 	console.log(res, "测试题");
			// 	this.liveExamList = res.data.data.rows
			// },
			//发送消息
			sendOut() {
				let that = this
				if (!that.textarea1) {
					return false
				}
				let message = tim.createTextMessage({
					to: that.groupId,
					conversationType: TIM.TYPES.CONV_GROUP,
					payload:{
					text: that.textarea1,
				},
					needReadReceipt: false
				});
				console.log('message', message)
				message.nick = that.Appuser.userName
				let promise = tim.sendMessage(message);
				promise.then(function(imResponse) {
					console.log(imResponse, '发送成功’');
					that.messageerList.push({
						nick: that.Appuser.userName?that.Appuser.userName:message.from,
						content: message.payload.text,
						renderContent: message.payload.text,
						userID: message.from,
						avatar: message.avatar,
						time: message.time,
					});
					that.textarea1 = ''
					that.$refs.record.scrollTop = that.$refs.record.scrollHeight
					console.log(that.messageerList, '消息列表')
				}).catch(function(imError) {
					console.log('login error:', imError); // 登录失败的相关信息
					that.$message.error(`发送失败：${imError}`);
				});
			},
			//连麦--发起推流
			async Continuouswheat() {
				if (this.remoteStreamList.length == 0) {
					return false;
				}
				if (this.isAnchor == false) {
					console.log('我是主播')
					this.isAnchor = !this.isAnchor
					await this.client.switchRole('anchor'); //主播
					this.handlePublish()
				} else {
					console.log('我是观众')
					this.isAnchor = !this.isAnchor
					await this.client.switchRole('audience'); //观众
				}
			},
			change_isfollow() {
				this.isfollow = !this.isfollow
			},
			async generateInviteLink() {
				var that = this
				// if (!this.isHostMode) {
				// 	return;
				// }
				console.log('获取签名开始')
				//生成签名
				const res = await user_sign({
					expire: 72000
				})
				console.log(res, '获取签名结束')
				const inviteUserSig = res.data.userSign
				this.inviteLink = encodeURI(
					`${location.origin}${location.pathname}#/invite?sdkAppId=${this.sdkAppId}&userSig=${inviteUserSig}&roomId=${this.roomId}&userId=${this.userId}`
				);
				//链接im通讯
				// console.log('this.userId:', this.userId, 'inviteUserSig:', inviteUserSig)
				// that.listenMsg()
				// tim.on(eventName, handler, context);
				let promise = tim.login({
					userID: this.userId,
					userSig: inviteUserSig
				});
				promise.then(function(imResponse) {
					console.warn('连接im通讯成功', imResponse); // 登录失败的相关信息
				}).catch(function(imError) {
					console.warn('连接im通讯失败', imError); // 登录失败的相关信息
				});
			},
			// 监听事件
			handleTimEvents() {
				// 登录成功后会触发 SDK_READY 事件，该事件触发后，可正常使用 SDK 接口
				tim.on(TIM.EVENT.SDK_READY, this.onReadyStateUpdate);
				// SDK NOT READY
				// this.$tim.on(TIM.EVENT.SDK_NOT_READY, this.onNotReadyStateUpdate);
				// 被踢出
				// this.$tim.on(TIM.EVENT.KICKED_OUT, this.onKickOut);
				// SDK内部出错
				// this.$tim.on(TIM.EVENT.ERROR, this.onError);
				// 收到新消息
				tim.on(TIM.EVENT.MESSAGE_RECEIVED, this.onTextMessageReceived);
			},
			async onReadyStateUpdate() {
				let that = this
				// 修改个人标配资料
				let promise = tim.updateMyProfile({
				  nick: that.Appuser.userName,
				});
				promise.then(function(imResponse) {
				  console.log(imResponse.data); // 更新资料成功
				}).catch(function(imError) {
				  console.warn('updateMyProfile error:', imError); // 更新资料失败的相关信息
				});
			},
			// 接收文本消息
			onTextMessageReceived(event) {
				const messageList = event.data;
				console.log(event.data, '接收文本消息')
				messageList.forEach((message) => {
					if (message.type === TIM.TYPES.MSG_TEXT) {
						this.messageerList.push({
							nick: message.nick || message.from,
							content: message.payload.text,
							renderContent: message.payload.text,
							userID: message.from,
							avatar: message.avatar,
							time: message.time,
						});
					}
				});
				console.log(this.messageerList, '消息聊天列表')
			},
			handleCopyInviteLink() {
				navigator.clipboard.writeText(this.inviteLink);
				this.showCopiedTip = true;
				setTimeout(() => {
					this.showCopiedTip = false;
				}, 1500);
			},
			// 点击【Join Room】按钮
			async handleJoinRoom() {
				if (this.isHostMode) {
					if (!this.sdkAppId || !this.secretKey) {
						alert(this.$t("Please enter sdkAppId and secretKey"));
						return;
					}
					if (!this.userId || !this.roomId) {
						alert(this.$t("Please enter userId and roomId"));
						return;
					}
					const userSigGenerator = new LibGenerateTestUserSig(
						this.sdkAppId,
						this.secretKey,
						604800
					);
					this.userSig = userSigGenerator.genTestUserSig(this.userId);
				} else {
					if (
						!this.sdkAppId ||
						!this.inviteUserSig ||
						!this.userId ||
						!this.roomId
					) {
						alert(this.$t("Please reacquire the invitation link"));
						return;
					}
					this.userSig = this.inviteUserSig;
				}
				await this.initClient();
				await this.join();
				await this.initLocalStream();
				await this.playLocalStream();
				await this.publish(); //开启推流
				await this.handleUnpublish() //关闭推流

			},

			// 点击【Publish】按钮
			async handlePublish() {
				await this.publish();
			},

			// 点击【Unpublish】按钮
			async handleUnpublish() {
				await this.unPublish();
			},

			// 点击【Leave Room】按钮
			async handleLeave() {
				await this.leave();
			},

			// 点击【开始屏幕分享】按钮
			async handleStartScreenShare() {
				if (!this.sdkAppId || !this.secretKey) {
					alert(this.$t("Please enter sdkAppId and secretKey"));
					return;
				}
				await this.initShareClient();
				await this.initShareLocalStream();
				await this.handleShareJoin();
				await this.handleSharePublish();
			},

			// 点击【停止屏幕分享按钮】
			async handleStopScreenShare() {
				await this.handleShareUnpublish();
				await this.handleShareLeave();
			},

			// 显示成功的 Log
			addSuccessLog(log) {
				if (!this.isHostMode) {
					return;
				}
				this.logList.push({
					type: "success",
					log,
				});
				const {
					scrollHeight
				} = this.$refs.logContainer;
				this.$refs.logContainer.scrollTop = scrollHeight;
			},

			// 显示失败的 Log
			addFailedLog(log) {
				if (!this.isHostMode) {
					return;
				}
				this.logList.push({
					type: "failed",
					log,
				});
				const {
					scrollHeight
				} = this.$refs.logContainer;
				this.$refs.logContainer.scrollTop = scrollHeight;
			},
			reportSuccessEvent(name) {
				this.$aegis.reportEvent({
					name,
					ext1: `${name}-success`,
					ext2: "webrtcQuickDemoVue2",
					ext3: this.sdkAppId,
				});
			},
			reportFailedEvent(name, error, type = "live") {
				console.log(name, error, type, '123456789')
				this.$aegis.reportEvent({
					name,
					ext1: `${name}-failed#${this.roomId}*${
          type === "share" ? this.shareUserId : this.userId
        }*${error.message}`,
					ext2: "webrtcQuickDemoVue2",
					ext3: this.sdkAppId,
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	:deep .el-icon-close {
		color: #fff;
	}

	:deep .el-input__inner {
		border: 1px solid #000;
		color: #fff !important;
		border-radius: 30px !important;
		background-color: #000 !important;
	}

	.rtc-container {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #0F0C3B;

		.label {
			margin: 14px 0 6px;
			text-align: left;
			font-weight: bold;
		}

		.control-container {
			text-align: left;
			margin-bottom: 10px;

			div:not(:nth-last-child(1)) {
				margin-bottom: 10px;
			}

			.button:not(:first-child) {
				margin-left: 2px;
			}
		}

		.invite-link-container {
			width: 100%;
			color: #084298;
			background-color: #cfe2ff;
			position: relative;
			padding: 10px 16px;
			margin-bottom: 16px;
			border: 1px solid #b6d4fe;
			border-radius: 0.25rem;

			.invite-input {
				margin-top: 10px;
			}

			.invite-btn {
				display: flex;
				cursor: pointer;
			}
		}

		.info-container {
			width: 100%;
			display: flex;
			flex-direction: column;
			// justify-content: space-between;

			.log-container {
				flex-grow: 1;
				border: 1px solid #dddddd;
				height: 360px;
				padding: 10px;
				margin-right: 16px;
				overflow-y: scroll;

				.log-label {
					margin: 0 0 6px;
					font-weight: bold;
				}

				.log-state {
					display: inline-block;
					margin-right: 6px;
				}

				>div {
					font-size: 12px;
				}
			}

			.Smallwindow {
				width: 40%;
				height: 200px;
				position: absolute;
				right: 2%;
				top: 40%;
				// height: 160px !important;
			}

			.local-stream-container {
				// width: 100%;
				// height: 160px !important;
				position: relative;

				// flex-shrink: 0;
				.local-stream-content {
					width: 100%;
					height: 100%;
				}

				.local-stream-control {
					width: 100%;
					height: 30px;
					position: absolute;
					bottom: 0;
					background-color: rgba(0, 0, 0, 0.3);
					display: flex;
					justify-content: flex-end;
					align-items: center;
					padding: 0 10px;

					.control {
						margin-left: 10px;
					}

					.icon-class {
						color: #fff;
						cursor: pointer;
						width: 20px;
						height: 20px;
					}
				}
			}
		}

		.info-container-mobile {
			// display: block;
			// .log-container {
			// 	margin-right: 0;
			// }
			// .local-stream-container {
			// 	width: 100%;
			// 	height: 240px;
			// }
		}

		.remote-container {
			width: 100%;

			// height: 240px;
			// overflow: hidden;
			// height: 160px;
			// margin-top: 10px;
			// display: flex;
			// flex-wrap: wrap;
			div {
				width: 100%;
				height: 100%;
			}

			.remote-stream-container {
				width: 100%;
				height: 240px;
			}
		}

		/* 是否关注颜色 */
		.follow01 {
			width: 55px;
			height: 32px;
			border-radius: 251px;
			background: #1FCEA1;
		}

		.follow02 {
			width: 55px;
			height: 32px;
			border-radius: 251px;
			background: #0F0C3B;
		}
	}
</style>

<i18n>
	{
	"en": {
	"Operation": "Operation",
	"Join Room": "Join Room",
	"Publish": "Publish",
	"Unpublish": "Unpublish",
	"Leave Room": "Leave Room",
	"Start Screen Share": "Start Screen Share",
	"Stop Screen Share": "Stop Screen Share",
	"Please enter sdkAppId and secretKey": "Please enter sdkAppId and secretKey",
	"Please enter userId and roomId": "Please enter userId and roomId",
	"Please reacquire the invitation link": "Please reacquire the invitation link!"
	},
	"zh": {
	"Operation": "操作",
	"Join Room": "进入房间",
	"Publish": "发布流",
	"Unpublish": "取消发布流",
	"Leave Room": "离开房间",
	"Start Screen Share": "开始共享屏幕",
	"Stop Screen Share": "停止共享屏幕",
	"Please enter sdkAppId and secretKey": "请输入 sdkAppId 和 secretKey",
	"Please enter userId and roomId": "请输入 userId 和 roomId",
	"Please reacquire the invitation link": "请重新获取邀请链接！"
	}
	}
</i18n>
