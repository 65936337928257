<template>
	 <keep-alive>
	      <router-view/>
	    </keep-alive>
</template>

<script>
export default {
  name: 'App',
  mounted() {
    this.$aegis.reportEvent({
      name: 'loaded',
      ext1: 'loaded-success',
      ext2: 'webrtcQuickDemoVue2',
    });
  },
};
</script>
<style>
	.flex {
		display: flex;
	}
	
	.fcc {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	
	.jcc {
		justify-content: center;
	
	}
	
	.jcsb {
		justify-content: space-between;
	}
	
	.jcsa {
		justify-content: space-around;
	}
	
	.aic {
		align-items: center;
	}
	
	.fdc {
		flex-direction: column;
	}
	
	.p_lftb {
		padding: 32px;
	}
	
	.p_lf32 {
		padding: 0 32px;
	}
	
	.p_lf22 {
		padding: 0 22px;
	}
	
	.p_lf64 {
		padding: 0 64px;
	}
	
	.ml_22 {
		margin-left: 12px;
	}
	
	.ml_22 {
		margin-left: 22px;
	}
	
	.mt_16 {
		margin-top: 16px;
	}
	
	.mt_20 {
		margin-top: 20px;
	}
	
	.mt_28 {
		margin-top: 28px;
	}
	
	.mt_48 {
		margin-top: 48px;
	}
	
	.mt_64 {
		margin-top: 64px;
	}
	
	.m_lf16 {
		margin-left: 16px;
		margin-right: 16px;
	}
	
	.m_lf32 {
		margin: 0 32px;
	}
	
	.text_style01 {
		font-size: 28px;
		color: #fff;
	}
	
	.text_sty02 {
		font-size: 28px;
		color: #333333;
	}
	
	.text_sty03 {
		font-size: 24px;
		color: #999999;
	}
	
	.text_sty04 {
		font-size: 36px;
		color: #333333;
	}
	
	.text_sty05 {
		font-size: 28px;
		color: #999999;
	}
	
	.text_sty06 {
		font-size: 30px;
		color: #FF9E0B;
	}
</style>
