// 配置基础的axios
import axios from "axios";
import { MessageBox , Loading } from "element-ui";
import _ from "lodash";
let baseUrl = "https://a.qmyd.vip"
// let baseUrl = "https://web.qmyd.vip"
// let baseUrl = "http://192.168.0.120:8080" //明凯


// 创建一个新的axios对象
const instance = axios.create({
  baseURL: baseUrl,
});

//loading对象
let loading;

//当前正在请求的数量
let needLoadingRequestCount = 0;

//显示loading
function showLoading(target) {
  // 后面这个判断很重要，因为关闭时加了抖动，此时loading对象可能还存在，
  // 但needLoadingRequestCount已经变成0.避免这种情况下会重新创建个loading
  if (needLoadingRequestCount === 0 && !loading) {
    loading = Loading.service({
      lock: true,
      text: "Loading...",
      background: "rgba(0,0,0, 0.5)",
      target: target || "body"
    });
  }
  needLoadingRequestCount++;
}

//隐藏loading
function hideLoading() {
  needLoadingRequestCount--;
  needLoadingRequestCount = Math.max(needLoadingRequestCount, 0); //做个保护
  if (needLoadingRequestCount === 0) {
    //关闭loading
    toHideLoading();
  }
}

//防抖：将 300ms 间隔内的关闭 loading 便合并为一次。防止连续请求时， loading闪烁的问题。
var toHideLoading = _.debounce(() => {
  loading.close();
  loading = null;
}, 300);

// 请求拦截
instance.interceptors.request.use(
  function(config) {
    config.headers.ContentType = "application/x-www-form-urlencoded"
    if (config.headers.showLoading !== false) {
      showLoading(config.headers.loadingTarget);
    }
	 config.headers['Authorization'] = 'Bearer ' + localStorage.getItem("token") // 让每个请求携带自定义token 请根据实际情况自行修改
	 // config.headers['Authorization'] = 'Bearer ' + 'eyJhbGciOiJIUzUxMiJ9.eyJ1c2VyX2lkIjoxNzMsInVzZXJfa2V5IjoiZWYzYjRmODAtYmJiZS00NDdlLTgzOTgtMjk4NTJmMTViMTRlIiwidXNlcm5hbWUiOiLlsI_nhorppbzlubIifQ.gcNlx6RqAL_Z9e8XlmuaGdzcywVhZ0G4uR1WxzXzduGBvSDhGR_9BWR6U2p5VrDdlSMHV4bJ-xOaQaCr_ih_lg'
	 // 让每个请求携带自定义token 请根据实际情况自行修改
    return config;
  },
  function(error) {
    if (config.headers.showLoading !== false) {
      hideLoading();
    }
    MessageBox({
      type:"error",
      message:'Request timeout, please refresh and try again'
    });
    return Promise.reject(error);
  }
);

// 响应拦截
// 所有的网络请求返回数据之后先执行此方法
instance.interceptors.response.use(
  function(response) {
    if (response.config.headers.showLoading !== false) {
      hideLoading();
    }
    return response;
  },
  function(error) {
    if (error.config.headers.showLoading !== false) {
      hideLoading();
    }
    if (error.response && error.response.data && error.response.data.msg ) {
      var jsonObj = JSON.parse(error.response.data.msg);
      // MessageBox({
      //   type:"error",
      //   message:jsonObj.MessageBox
      // });
    } else {
      // MessageBox({
      //   type:"error",
      //   message:error.MessageBox
      // });
    }
    return Promise.reject(error);
  }
);

export default instance;

// export function get(url,parmas){
//   return instance.get(url,{
//     params
//   });
// }

// export function post(url,data){
//   return instance.post(url,data);
// }

// export function del(url){
//   return instance.delete(url)
// }

// export function put(url,data){
//   return instance.put(url,data)
// }

