<!--
 * @Description: quick demo - vue2 版本页面
 * @Date: 2022-03-14 16:56:36
 * @LastEditTime: 2022-03-29 17:01:32
-->
<template>
  <div id="app">
    <!-- 头部栏 -->
    <!-- <comp-nav></comp-nav> -->
    <div class="content" :class="$isMobile && 'content-mobile'">
      <!-- quick demo 使用指引 -->
      <!-- <comp-guidance></comp-guidance> -->
      <!-- sdkAppId、secretKey、userId、roomId 参数输入区域 -->
      <!-- <p class="label">{{ $t('Params') }}</p>
      <div class="param-container" :class="$isMobile && 'param-container-mobile'">
        <comp-info-input
          label="sdkAppId" type="number" @change="handleValueChange($event, 'sdkAppId')"></comp-info-input>
        <comp-info-input
          label="secretKey" @change="handleValueChange($event, 'secretKey')"></comp-info-input>
        <comp-info-input
          label="userId" @change="handleValueChange($event, 'userId')"></comp-info-input>
        <comp-info-input
          label="roomId" type="number" @change="handleValueChange($event, 'roomId')"></comp-info-input>
      </div>
      <div class='alert'>
        <el-alert
          type="error"
          :closable="false"
        >
          <span>{{ $t("Alert")}} <a target="_blank" :href="$t('Url')">{{ $t("Click")}}</a></span>
        </el-alert>
      </div> -->
      <!-- 设备选择区域 -->
      <!-- <p class="label">{{ $t('Device Select') }}</p>
      <div class="param-container" :class="$isMobile && 'param-container-mobile'">
        <comp-device-select
          deviceType="camera" @change="handleValueChange($event, 'cameraId')"></comp-device-select>
        <comp-device-select
          deviceType="microphone" @change="handleValueChange($event, 'microphoneId')"></comp-device-select>
      </div> -->
      <!-- rtc 房间 -->
      <comp-room
        :sdkAppId="Number(sdkAppId)"
        :secretKey="secretKey"
        :userId="userId"
        :roomId="Number(roomId)"
        :cameraId="cameraId"
        :microphoneId="microphoneId"
        ></comp-room>
    </div>
  </div>
</template>

<script>
import compRoom from '@/components/comp-room.vue';
import { clearUrlParam } from '@/utils/utils';

export default {
  name: 'App',
  components: {
    compRoom,
  },
  data() {
    return {
      sdkAppId: 0,
      secretKey: '',
      userId: '',
      roomId: 0,
      cameraId: '',
      microphoneId: '',
    };
  },
  created(){
	  this.sdkAppId = 1400728399;
	  this.secretKey = "7c4a925eb1a7cf23f208a58deefaac2aee2996e39e03f38b12c6f39803854a14"
	  
	  // this.sdkAppId = 1400780809;
	  // this.secretKey = "2a63ae6ef8e26acb3ba1ef4f194bdeb5b0014900a9605fa4fad01b75925df029"
	  // this.userId = '143';
	  // this.roomId = Number(968131);
	  if(this.$route.query && this.$route.query.userId && this.$route.query.roomId &&this.$route.query.liveid){
	  	this.userId = this.$route.query.userId;
	  	this.roomId = Number(this.$route.query.roomId)
	  	this.liveid = this.$route.query.liveid;
	  	localStorage.setItem('userId', this.$route.query.userId)
	  	localStorage.setItem('roomId', this.$route.query.roomId)
	  	localStorage.setItem('liveid', this.$route.query.liveid)
		localStorage.setItem('token', this.$route.query.token)
	  }else{
	  	this.userId = localStorage.getItem("userId")
	  	this.roomId = Number(localStorage.getItem("roomId"))
	  	this.liveid = localStorage.getItem("liveid")
	  }
	  // http://localhost:8081/#/?liveid=a424dfcf701e45039d6de875534dd749&userId=173&roomId=606813&token=eyJhbGciOiJIUzUxMiJ9.eyJ1c2VyX2lkIjoxNzMsInVzZXJfa2V5IjoiZWYzYjRmODAtYmJiZS00NDdlLTgzOTgtMjk4NTJmMTViMTRlIiwidXNlcm5hbWUiOiLlsI_nhorppbzlubIifQ.gcNlx6RqAL_Z9e8XlmuaGdzcywVhZ0G4uR1WxzXzduGBvSDhGR_9BWR6U2p5VrDdlSMHV4bJ-xOaQaCr_ih_lg
	  // this.userId = '173'
	  // this.roomId = Number(6426)
  },
  methods: {
    handleValueChange(value, key) {
      this[key] = value;
    },
  },
  mounted() {
    clearUrlParam();
  },
};
</script>

<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  padding-bottom: 40px;
  .content {
    width: 80%;
    margin: 0 auto;
    max-width: 1320px;
    .alert {
      padding-top: 20px;
      font-size: 16px !important;
    }
    &.content-mobile {
      width: 100%;
      padding: 0 16px 20px;
    }
    .label {
      margin: 14px 0 6px;
      text-align: left;
      font-weight: bold;
    }
    .param-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      div {
        width: calc((100% - 20px) / 2);
        margin-bottom: 10px;
      }
      div:nth-last-child(2), div:nth-last-child(1) {
        margin-bottom: 0;
      }
      &.param-container-mobile {
        div {
          width: 100%;
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>

<i18n>
{
	"en": {
		"Params": "Params",
    "Device Select": "Device Select",
    "Alert": "Notes: this Demo is only applicable for debugging. Before official launch, please migrate the UserSig calculation code and key to your backend server to avoid unauthorized traffic use caused by the leakage of encryption key.",
    "Click": "View documents",
    "Url": "https://intl.cloud.tencent.com/document/product/647/35166"
	},
	"zh": {
		"Params": "参数",
    "Device Select": "设备选择",
    "Alert": "注意️：本 Demo 仅用于调试，正式上线前请将 UserSig 计算代码和密钥迁移到您的后台服务器上，以避免加密密钥泄露导致的流量盗用。",
    "Click": "查看文档",
    "Url": "https://cloud.tencent.com/document/product/647/17275"
	}
}
</i18n>

