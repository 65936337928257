import request from "../utils/request";

export function exam_list(data) {
	return request.post('/electron/live/exam/list', data, {
		params: data
	})
}

// export function user_sign(data) {
// 	console.log(data,111111)
// 	return request.get('/electron/live-cloud-setting/user-sign', data, {
// 		data: data
// 	})
// }
//生成签名
export function user_sign(data) {
  return request({
    url: '/electron/live-cloud-setting/user-sign',
    method: 'get',
	params: data
  })
}

//获取用户信息
export function applet_info(data) {
  return request({
    url: '/applet/info',
    method: 'post',
	params: data
  })
}
//获取直播详情
export function live_detail(data) {
  return request({
    url: `/applet/live/getLiveInfo/${data.id}`,
    method: 'get',
  })
}
